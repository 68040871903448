


























































































import { FormHelper, Toast } from "@/mixins"
import { Component, Mixins } from "vue-property-decorator"
import { News } from "@/api"
import { NewsType } from "@/types/enums"

@Component
export default class AddNewsModal extends Mixins(FormHelper, Toast) {
  isOpen = false
  isCreate = false
  planifiedAt = ''
  newsTypes = [
    { id: NewsType.NONE, label: 'None' },
    { id: NewsType.FEATURED, label: 'Featured' },
    { id: NewsType.PATCH, label: 'Patch' },
    { id: NewsType.SHOP, label: 'Shop' },
  ]
  newsData = {
    tags: [],
    title: "",
    description: "",
    linkTo: "",
    imageUrl: "",
    type: '',
  }

  async mounted() {
    this.$root.$on("openAddNewsModal", (news?: any) => {
      this.isOpen = true
      this.isCreate = true

      if (news) {
        this.isCreate = false
        this.newsData = news
      }
    })
  }

  resetForm() {
    this.newsData = {
      tags: [],
      title: "",
      description: "",
      linkTo: "",
      imageUrl: "",
      type: '',
    }
  }

  get areFieldsFilled() {
    return (
      this.newsData.tags.length > 0 &&
      this.newsData.title &&
      this.newsData.description &&
      this.newsData.linkTo &&
      this.newsData.imageUrl &&
      this.newsData.type
    )
  }

  async addNews() {
    try {
      const response = await News.addNews({
        ...this.newsData,
        createdAt: this.planifiedAt || new Date()
      })
  
      if (response.success) {
        this.$root.$emit("refreshNews")
      }
    } catch (e) {
      this.showErrorToast({
        title: "An error occurred while adding the news"
      })
      console.error("Error when trying to add news", e)
    }
  }

  async modifyNews() {
    try {
      const response = await News.updateNews(this.newsData)

      if (response.success) {
        this.$root.$emit("refreshNews")
      }
    } catch (e) {
      this.showErrorToast({
        title: "An error occurred while modifying the news"
      })
      console.error("Error when trying to modify news", e)
    }
  }
  async submit() {
    return this.isCreate ? this.addNews() : this.modifyNews()
  }
}
